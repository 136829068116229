import React, { Suspense, lazy } from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route
} from "react-router-dom";
import styled from '@emotion/styled';
import loaderImg from './assets/images/design_elements/loader-blue.gif';
import { ThemeProvider } from '@emotion/react';
import { defaultTheme } from './styles/theme';
import EnvProvider from './EnvProvider';
import TagManager from 'react-gtm-module';
import logo from './assets/images/logo-study_kik.svg';
const Home = lazy(() => import('./containers/Home'));
const About = lazy(() => import('./containers/About'));
const Contact = lazy(() => import('./containers/Contact'));
const Patients = lazy(() => import('./containers/Patients'));
const Studies = lazy(() => import('./containers/Patients/Studies'));
const Sites = lazy(() => import('./containers/Sites'));
const StudySurvey = lazy(() => import('./containers/StudySurvey'));
const SelectASite = lazy(() => import('./containers/SelectASite'));
const Portal = lazy(() => import('./containers/Sites/Portal'));
const Plans = lazy(() => import('./containers/Sites/Plans'));
const VirtualVisits = lazy(() => import('./containers/Sites/VirtualVisits'));
const Sponsors = lazy(() => import('./containers/Sponsors'));
const StudyWebsites = lazy(() => import('./containers/Sponsors/StudyWebsites'));
const Econsent = lazy(() => import('./containers/Sponsors/Econsent'));
const Apps = lazy(() => import('./containers/Sponsors/Apps'));
const Reporting = lazy(() => import('./containers/Sponsors/Reporting'));
const SearchResults = lazy(() => import('./containers/SearchResults'));
const FormSuccess = lazy(() => import('./containers/FormSuccess'));
const International = lazy(() => import('./containers/International'));
const NotFound404 = lazy(() => import('./containers/NotFound404'));
const PrivacyPolicy = lazy(() => import('./containers/PrivacyPolicy'));
const TermsOfUse = lazy(() => import('./containers/TermsOfService'));
const CookiePolicy = lazy(() => import('./containers/CookiePolicy'));
const Conference = lazy(() => import('./containers/BagGiveaway/Conference'));
const ConferenceDiscount = lazy(() => import('./containers/ConferenceDiscount'));

const ScrollToTop = lazy(() => import('./ScrollToTop'));
const Unsubscribe = lazy(() => import('./containers/Unsubscribe'))

const tagManagerArgs = {
  gtmId: 'GTM-NL8B399'
}

TagManager.initialize(tagManagerArgs);

const LoadingElement = styled.div`
  background-image: url(${loaderImg});
  background-repeat: no-repeat;
  background-position: center;
  background-size: 132px;
  height: 100%;
  width: 100%;
  position: absolute;
  z-index: 9999;
  top: 0;
  overflow: hidden;
  padding-bottom: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #FFF;

  img {
    max-height: 70px;
    width: auto;
  }
`;

const countries = ["/ca", "/gb", "/pl", "/de", "/hu", "/br", "/jp", "/it", "/fr", "/cz", "/at", "/au", "/mx", "/nz", "/pr"];

function App() {
  return (
    <Router>
      <EnvProvider>
        <ThemeProvider theme={defaultTheme}>
          <Suspense fallback={<LoadingElement><img alt="loading logo" src={logo} /></LoadingElement>}>
            <ScrollToTop />
            <Routes>
              <Route path="/privacy-policy" element={<PrivacyPolicy />} />
              <Route path="/terms-of-service" element={<TermsOfUse />} />
              <Route path="/cookie-policy" element={<CookiePolicy />} />
              <Route path="/success" element={<FormSuccess />} />
              <Route path="/contact" element={<Contact />} />
              <Route path="/about" element={<About />} />
              <Route path="/patients/what-are-clinical-trials" element={<Studies />} />
              {["/patients", "/patients/what-is-studykik"].map(route => <Route path={route} element={<Patients />} />)}
              <Route path="/sites/portal" element={<Portal />} />
              <Route path="/sites/plans" element={<Plans />} />
              <Route path="/sites/virtual-visits" element={<VirtualVisits />} />
              {["/sites", "/sites/recruitment"].map(route => <Route path={route} element={<Sites />} />)}
              <Route path="/sponsors/econsent" element={<Econsent />} />
              <Route path="/sponsors/study-websites" element={<StudyWebsites />} />
              <Route path="/sponsors/apps" element={<Apps />} />
              <Route path="/sponsors/trial-reporting" element={<Reporting />} />
              {["/sponsors", "/sponsors/care-package"].map(route => <Route path={route} element={<Sponsors />} />)}
              <Route path={"/giveaway/conference"} element={<Conference />} />
              <Route path={"/discount/conference"} element={<ConferenceDiscount />} />
              <Route path="/unsubscribe" element={<Unsubscribe />} />
              <Route path="/search" element={<SearchResults />} />
              <Route path="/study-survey/:formId/:patientUUID" element={<StudySurvey />} />
              <Route path="/select-a-site" element={<SelectASite />} />
              {countries.map(country => <Route path={country} element={<International />} />) }
              {countries.map(country => <Route path={`${country}/search`} element={<SearchResults />} />) }
              <Route path="/" exact element={ <Home />} />
              <Route path="*" element={<NotFound404 />} />
            </Routes>
          </Suspense>
        </ThemeProvider>
      </EnvProvider>
    </Router>
  );
}

export default App;