export function numberWithCommas(val){
  while (/(\d+)(\d{3})/.test(val.toString())){
    val = val.toString().replace(/(\d+)(\d{3})/, '$1,$2');
  }
  return val;
}

export function emptyObject(obj) {
  return obj && Object.keys(obj).length === 0 && obj.constructor === Object;
}

export function imageLoader(names, files, onAllLoaded) {
  var i = 0, numLoading = names.length;
  const images = {};
  const onload = () => --numLoading === 0 && onAllLoaded();
  while (i < names.length) {
      const img = images[names[i]] = new Image();
      img.src = files[i++];
      img.onload = onload;
  }   
  return images;
}

export function getQueryStringObject() {
  const qs = window.location.search.split('?')[1];
  const qsArr = (qs) ? qs.split('&') : [];

  const qsObj = {};

  // if query params exist, loop
  if (qsArr.length) {
      qsArr.forEach(param => {
          const set = param.split('=');
          qsObj[set[0]] = set[1];
      });
  }

  return qsObj;
}
