import './global.css';

// COLORS
const BLACK = '#000000';
const BLUE = '#00AFEF';
const BLUE_LIGHT = '#0BBDFE';
const BLUE_DARK = '#00A3DE';
const BLUE_ULTRA_LIGHT = '#B3E7FA';
const GRAY = '#ADADAD';
const GRAY_DARK = '#333333';
const GRAY_LIGHT = '#FAF8F5';
const GRAY_COOL = '#F2F0ED';
const GRAY_MEDIUM = '#828282';
const NEUTRAL_6 = '#FAFBFD';
const NEUTRAL_7 = '#F6F5F3';
const GREEN = '#35CE8D';
const GREEN_DARK = '#009C5F';
const NAVY = '#08576D';
const ORANGE = '#FFA13E';
const RED = '#FB7272';
const TURQUOISE = '#59FAC3';
const WHITE = '#FFFFFF';
const YELLOW = '#FFFF3B';
const BEIGE = '#F9F6F2';

//FONTS
const PROXIMA = '"Proxima Nova", Arial, sans-serif';
const HEEBO = '"Heebo", Helvetica, sans-serif';
const VERVEINE = '"Verveine", Arial, sans-serif';

export const defaultTheme = {
  breakpoints: {
    xs: '374px',
    sm: '576px',
    md: '767px',
    lg: '992px',
    xl: '1200px',
    xxl: '1400px'
  },
  colors: {
    primary1: BLUE,
    primary2: ORANGE,
    primary3: GREEN,
    primary4: NAVY,
    primary5: GREEN_DARK,
    secondary1: YELLOW,
    secondary2: TURQUOISE,
    secondary3: BLUE_LIGHT,
    secondary4: BLUE_DARK,
    secondary5: BLUE_ULTRA_LIGHT,
    neutral1: GRAY_DARK,
    neutral2: GRAY_LIGHT,
    neutral3: GRAY,
    neutral4: GRAY_COOL,
    neutral5: GRAY_MEDIUM,
    neutral6: NEUTRAL_6,
    neutral7: NEUTRAL_7,
    contrast1: BLACK,
    contrast2: WHITE,
    contrast3: BEIGE,
    error: RED
  },
  fonts: {
    primary: PROXIMA,
    secondary: HEEBO,
    accent: VERVEINE
  },
  fontSizes: {
    xs: '13px',
    sm: '16px',
    std: '18px',
    smd: '20px',
    md: '24px',
    lg: '30px',
    xl: '40px',
    xxl: '56px',
    xxxl: '60px'
  },
  margins: {
    xxxxs: '8px',
    xxxs: '16px',
    xxs: '20px',
    xs: '24px',
    sm: '28px',
    std: '32px',
    md: '36px',
    lg: '40px',
    xl: '44px',
    xxl: '48px'
  },
  lineHeights: {
    xxs: '10px',
    xs: '16px',
    sm: '20px',
    std: '27px',
    md: '32px',
    lg: '38px',
    xl: '56px'
  }
};

