import React from 'react';
import EnvContext from './EnvContext';
import { getLocale } from './localization/helpers';
import rtlDetect from 'rtl-detect';

function EnvProvider(props) {
  function setBaseUrl(env) {
    // TODO: Look into doing this using env file with no need for this check
    if (env === "development") {
      return 'https://web-tek.studykiktest.com';
    } else {
      if (typeof window !== 'undefined') {
        const { protocol, host } = window.location;
        return `${protocol}//${host}`;
      } else {
        return 'https://studykik.com';
      }
    }
  }

  const providerData = {
    apiBaseUrl: process.env.REACT_APP_API_URL,
    envBaseUrl: setBaseUrl(process.env.NODE_ENV),
    selfAssignBaseUrl: process.env.REACT_APP_SELF_ASSIGN_BASE_URL,
    googleApi: process.env.REACT_APP_GOOGLE_API, // Shift left
    locale: getLocale(),
    isRtL: rtlDetect.isRtlLang(navigator.language),
  
  };

  return (
    <EnvContext.Provider value={providerData}>
      {props.children} 
    </EnvContext.Provider>
  );
}

export default EnvProvider;